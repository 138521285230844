import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import {
  AggregateRating,
  BreadcrumbList,
  Hotel,
  WithContext,
} from 'schema-dts';
import { Breadcrumb, TravelType } from '@hotelplan/graphql.types';
import { capitalize, formatPrice, withoutTags } from '@hotelplan/libs.utils';
import useGetPriceOverview from 'components/domain/orl/useGetPriceOverview';
import { getGeoStructuredData } from './structured-data.utils';
import usePdpStructuredData from './usePdpStructuredData';

export default function PdpStructuredData() {
  const { asPath } = useRouter();
  const [t, { language }] = useTranslation(['common', 'orl']);

  const { data: priceDateOverview = [] } = useGetPriceOverview();
  const {
    productName,
    description,
    url,
    imageUrl,
    taRating,
    address,
    breadcrumbs,
  } = usePdpStructuredData();

  // does not appear if hotel has no TA rating
  const aggregateRating: AggregateRating | undefined = !taRating
    ? undefined
    : {
        '@type': 'AggregateRating',
        bestRating: 5,
        ratingValue: taRating?.rating ? taRating.rating / 10 : undefined,
        reviewCount: taRating.count ?? undefined,
      };

  const getNightsText = (count: number): string =>
    t('common:nights.count', { count });

  const isPackage = priceDateOverview[0]?.travelType === TravelType.Package;

  const nightsText = getNightsText(priceDateOverview[0]?.duration || 1);
  const priceText = priceDateOverview[0]?.pricePerPerson
    ? formatPrice(priceDateOverview[0].pricePerPerson)
    : '';
  const boardTypeText = capitalize(priceDateOverview[0]?.board || '');

  const priceRange = `${t('common:perPerson.from')} ${priceText} (${
    isPackage ? t('orl:package.title') + ', ' : ''
  }${nightsText}${boardTypeText ? ', ' + boardTypeText : ''})`;

  const hotelStructuredData: WithContext<Hotel> = {
    '@context': 'https://schema.org',
    '@type': 'Hotel',
    name: productName,
    description: withoutTags(description),
    url,
    image: imageUrl,
    aggregateRating,
    priceRange,
    address: {
      '@type': 'PostalAddress',
      addressCountry: address,
    },
  };

  const adjustedBreadcrumbs = [
    ...breadcrumbs,
    {
      caption: productName,
      link: { uri: asPath },
    },
  ] as Array<Breadcrumb>;

  const geoStructuredData: WithContext<BreadcrumbList> = useMemo(
    () =>
      breadcrumbs.length
        ? getGeoStructuredData(adjustedBreadcrumbs, language)
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productName, breadcrumbs]
  );

  return (
    <>
      <HotelStructuredData data={hotelStructuredData} />
      <GeoStructuredData data={geoStructuredData} />
    </>
  );
}

function HotelStructuredData({ data }: { data: WithContext<Hotel> }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  );
}

function GeoStructuredData({ data }: { data: WithContext<BreadcrumbList> }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  );
}
