import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const AttributesWrapper = styled.div(
  sx2CssThemeFn(() => ({
    display: `grid`,
    gridTemplateColumns: [null, `repeat(3, 1fr)`],
    gap: [null, '0px 40px'],
    gridAutoFlow: [null, 'row'],

    '.accordionItem .accordionButtonWrap': {
      p: 0,
    },

    '.accordionButton': {
      width: '100%',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      '.chevron': {
        color: 'textGray',
        ml: 2,
      },
    },
  }))
);

export default AttributesWrapper;
