import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { PriceDateOverviewItemFragmentDoc } from './PriceDateOverviewItem.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetOrlPriceDateOverviewQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  searchControl: Types.PdpSearchControlCriteriaInput;
  filters: Types.PdpFilterCriteriaInput;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetOrlPriceDateOverviewQuery = {
  __typename?: 'Query';
  orl: {
    __typename?: 'OrlContext';
    priceDateOverview?: {
      __typename?: 'PdpPriceDateOverviewComponent';
      orlItems?: Array<{
        __typename?: 'SingleOrlItem';
        duration: number;
        travelType: Types.TravelType;
        board: string;
        productClass: Types.ProductClass;
        departureDate: string;
        returnDate: string;
        id: string;
        waitingScreenImage: string;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        pricePerPerson: {
          __typename?: 'Price';
          amount: string;
          currency: string;
        };
        totalPrice: { __typename?: 'Price'; amount: string; currency: string };
        rooms: Array<{
          __typename?: 'OrlRoom';
          adults: number;
          children: number;
          roomType: {
            __typename?: 'RoomType';
            description: string;
            code: string;
          };
          boardType: {
            __typename?: 'BoardType';
            description: string;
            code: string;
          };
        }>;
        outboundFlight?: {
          __typename?: 'Flight';
          departureDate: any;
          departureTime: any;
          departure: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
          segments: Array<{
            __typename?: 'FlightSegment';
            airline: { __typename?: 'Airline'; name: string };
          }>;
        } | null;
        inboundFlight?: {
          __typename?: 'Flight';
          departureDate: any;
          departureTime: any;
          departure: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
          segments: Array<{
            __typename?: 'FlightSegment';
            airline: { __typename?: 'Airline'; name: string };
          }>;
        } | null;
      }> | null;
    } | null;
  };
};

export const GetOrlPriceDateOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetORLPriceDateOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PdpSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PdpFilterCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDateOverview' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orlItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'PriceDateOverviewItem',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PriceDateOverviewItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetOrlPriceDateOverviewQuery__
 *
 * To run a query within a React component, call `useGetOrlPriceDateOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrlPriceDateOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrlPriceDateOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchControl: // value for 'searchControl'
 *      filters: // value for 'filters'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetOrlPriceDateOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetOrlPriceDateOverviewQuery,
    GetOrlPriceDateOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetOrlPriceDateOverviewQuery,
    GetOrlPriceDateOverviewQueryVariables
  >(GetOrlPriceDateOverviewDocument, options);
}
export function useGetOrlPriceDateOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrlPriceDateOverviewQuery,
    GetOrlPriceDateOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetOrlPriceDateOverviewQuery,
    GetOrlPriceDateOverviewQueryVariables
  >(GetOrlPriceDateOverviewDocument, options);
}
export type GetOrlPriceDateOverviewQueryHookResult = ReturnType<
  typeof useGetOrlPriceDateOverviewQuery
>;
export type GetOrlPriceDateOverviewLazyQueryHookResult = ReturnType<
  typeof useGetOrlPriceDateOverviewLazyQuery
>;
export type GetOrlPriceDateOverviewQueryResult = Apollo.QueryResult<
  GetOrlPriceDateOverviewQuery,
  GetOrlPriceDateOverviewQueryVariables
>;
