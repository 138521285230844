import { ClimateChartType } from '@hotelplan/graphql.types';

export const CHART_COLORS = {
  [ClimateChartType.MaxTemperaturePerMonth]: {
    barColor: '#FF0000',
    areaColor: '#FFD9D9',
  },
  [ClimateChartType.SunHoursPerMonth]: {
    barColor: '#F5A623',
    areaColor: '#FEF2DE',
  },
  [ClimateChartType.WaterTemperaturePerMonth]: {
    barColor: '#4A90E2',
    areaColor: '#E5EFFB',
  },
  [ClimateChartType.RainDaysPerMonth]: {
    barColor: '#0E3B84',
    areaColor: '#DBE2ED',
  },
  default: {
    barColor: '#FF0000',
    areaColor: '#FFD9D9',
  },
};
