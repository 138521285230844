import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Breadcrumb, TripAdvisorRating } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { useGetPdpPageContentQuery } from 'graphql/pdp/GetPdpPageContent.generated';
import { getAssetUrl } from './structured-data.utils';

interface IPdpStructuredData {
  productName: string;
  description: string;
  url: string;
  imageUrl: string;
  taRating: TripAdvisorRating;
  address: string;
  breadcrumbs: Array<Breadcrumb>;
}

export default function usePdpStructuredData(): IPdpStructuredData {
  const { id } = useObjectId();
  const { asPath } = useRouter();

  const { data } = useGetPdpPageContentQuery({
    variables: { id },
  });

  const { description, overview } = data?.pdp.content || {};
  const mediaItem = overview?.media?.mediaItems?.[0] || undefined;

  const breadCrumbItems =
    overview?.productBreadcrumbs.breadcrumbs.map(item => ({
      label: item.caption,
      link: getTargetPageData(item.link),
    })) || [];

  return useMemo(
    function () {
      return {
        productName: overview?.productName,
        description: description?.leadText,
        url: `${window.location.origin}${asPath}`,
        imageUrl: mediaItem ? getAssetUrl(mediaItem) : undefined,
        taRating: overview?.taRating,
        address: breadCrumbItems?.[0]?.label,
        breadcrumbs: overview?.breadcrumbsExtended?.breadcrumbs || [],
      };
    },
    [description, overview]
  );
}
