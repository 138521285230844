import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { useGetGeoBreadcrumbsQuery } from 'graphql/geo/GetGeoBreadcrumbs.generated';
import { getGeoStructuredData } from './structured-data.utils';

export default function GeoStructuredData(): React.ReactElement {
  const { id } = useObjectId();
  const [, { language }] = useTranslation();

  const { data, loading } = useGetGeoBreadcrumbsQuery({
    variables: {
      id,
    },
  });

  const breadcrumbs = useMemo(
    function () {
      if (data && !loading) {
        return data.geo.information.breadcrumbs.breadcrumbs;
      }

      return [];
    },
    [data, loading]
  );

  const geoStructuredData = useMemo(
    function () {
      return breadcrumbs.length
        ? getGeoStructuredData(breadcrumbs, language)
        : null;
    },
    [breadcrumbs, language]
  );

  if (!geoStructuredData) return null;

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(geoStructuredData) }}
      />
    </Head>
  );
}
