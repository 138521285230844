import { ChartType } from '@hotelplan/components.common.climate';

export const CHART_PARAMS = {
  mobile: {
    margin: {
      top: 50,
      bottom: 15,
      right: 0,
      left: -30,
    },
    fontSize: 13,
    barSize: 5,
    barRadius: [0, 0, 0, 0],
    strokeWidth: 2,
    fill: '#000',
    stroke: '#E1E1E1',
    xAxis: {
      dx: -5,
      dy: 5,
    },
    yAxis: {
      dx: -3,
      dy: -5,
    },
    area: false,
    xAxisLabel: {
      angle: 0,
      fontSize: 13,
      fill: '#666666',
      textAnchor: 'middle',
    },
    yAxisLabel: {
      position: 'insideTopLeft',
      dx: 60,
      dy: -45,
      fontSize: 13,
      fill: '#666666',
    },
    grid: {
      horizontal: true,
      vertical: false,
    },
    values: true,
  },
  desktop: {
    margin: {
      top: 50,
      bottom: 15,
      right: 0,
      left: -25,
    },
    fontSize: 13,
    barSize: 5,
    barRadius: [0, 0, 0, 0],
    strokeWidth: 2,
    fill: '#666',
    stroke: '#E1E1E1',
    xAxis: {
      dx: -10,
      dy: 7,
    },
    yAxis: {
      dx: -10,
      dy: -7,
    },
    area: false,
    xAxisLabel: {
      angle: 0,
      fontSize: 13,
      fill: '#666666',
      textAnchor: 'middle',
    },
    yAxisLabel: {
      position: 'insideTopLeft',
      dx: 55,
      dy: -45,
      fontSize: 13,
      fill: '#666',
    },
    grid: {
      horizontal: true,
      vertical: false,
    },
    values: true,
  },
};

export const CHART_PARAMS_BY_TYPE = {
  MAX_TEMPERATURE_PER_MONTH: {
    type: ChartType.Bar,
  },
  SUN_HOURS_PER_MONTH: {
    type: ChartType.Bar,
  },
  WATER_TEMPERATURE_PER_MONTH: {
    type: ChartType.Line,
  },
  RAIN_DAYS_PER_MONTH: {
    type: ChartType.Line,
  },
};
