import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { GroupOfProductFeatureItemsFragmentDoc } from 'graphql/productFeature/GroupOfProductFeatureItems.generated';
import { MediaGalleryItemFragmentDoc } from 'graphql/media/MediaGalleryItem.generated';
import { LocationFragmentDoc } from 'graphql/fragments/Location.generated';
import { DisrupterObjectFragmentDoc } from 'graphql/fragments/DisrupterObject.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetPdpPageContentQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetPdpPageContentQuery = {
  __typename?: 'Query';
  pdp: {
    __typename?: 'PdpContext';
    content: {
      __typename?: 'PdpContainer';
      overview: {
        __typename?: 'PdpOverviewComponent';
        productClass: Types.ProductClass;
        productName: string;
        hpRating: number;
        featuresSummary: Array<{
          __typename?: 'ProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'ProductFeature';
            id: string;
            name: string;
            enabled?: boolean | null;
          }>;
        }>;
        taRating?: {
          __typename?: 'TripAdvisorRating';
          rating?: number | null;
          count?: number | null;
        } | null;
        productBreadcrumbs: {
          __typename?: 'BreadcrumbsComponent';
          breadcrumbs: Array<{
            __typename?: 'Breadcrumb';
            caption: string;
            link?: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            } | null;
          }>;
        };
        breadcrumbsExtended: {
          __typename?: 'BreadcrumbsComponent';
          breadcrumbs: Array<{
            __typename?: 'Breadcrumb';
            caption: string;
            link?: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            } | null;
          }>;
        };
        media: {
          __typename?: 'MediaGallery';
          mediaItems: Array<
            | {
                __typename?: 'ExternalMediaItem';
                resource: string;
                title: string;
                thumbnail: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'ImageMediaItem';
                title: string;
                thumbnail: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                image: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'MusicMediaItem';
                resource: string;
                title: string;
                thumbnail: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'PdfMediaItem';
                resource: string;
                title: string;
                thumbnail: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'VideoMediaItem';
                resource: string;
                title: string;
                thumbnail: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
          >;
        };
        location: {
          __typename?: 'GeoLocation';
          airport?: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          } | null;
          continent?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          countryGroup?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          country?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          region?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          destination?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          resort?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
        };
        disrupters: Array<{
          __typename?: 'DisrupterObject';
          mainTitle?: string | null;
          subTitle?: string | null;
          text: string;
          fontColor?: string | null;
          backgroundColor?: string | null;
          iconType?: string | null;
        }>;
      };
      description: { __typename?: 'PdpDescriptionComponent'; leadText: string };
    };
    pdpOrlUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    pdpDescriptionUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    pdpRatingUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    pdpMapUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    pdpResortUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    pdpRegionUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    pdpClimateUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    pdpItineraryUrl?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
  };
};

export type PdpOverviewFragment = {
  __typename?: 'PdpOverviewComponent';
  productClass: Types.ProductClass;
  productName: string;
  hpRating: number;
  featuresSummary: Array<{
    __typename?: 'ProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
  }>;
  taRating?: {
    __typename?: 'TripAdvisorRating';
    rating?: number | null;
    count?: number | null;
  } | null;
  productBreadcrumbs: {
    __typename?: 'BreadcrumbsComponent';
    breadcrumbs: Array<{
      __typename?: 'Breadcrumb';
      caption: string;
      link?: {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      } | null;
    }>;
  };
  breadcrumbsExtended: {
    __typename?: 'BreadcrumbsComponent';
    breadcrumbs: Array<{
      __typename?: 'Breadcrumb';
      caption: string;
      link?: {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      } | null;
    }>;
  };
  media: {
    __typename?: 'MediaGallery';
    mediaItems: Array<
      | {
          __typename?: 'ExternalMediaItem';
          resource: string;
          title: string;
          thumbnail: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'ImageMediaItem';
          title: string;
          thumbnail: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'MusicMediaItem';
          resource: string;
          title: string;
          thumbnail: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'PdfMediaItem';
          resource: string;
          title: string;
          thumbnail: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'VideoMediaItem';
          resource: string;
          title: string;
          thumbnail: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
    >;
  };
  location: {
    __typename?: 'GeoLocation';
    airport?: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    } | null;
    continent?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    countryGroup?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    country?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    region?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    destination?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    resort?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
  };
  disrupters: Array<{
    __typename?: 'DisrupterObject';
    mainTitle?: string | null;
    subTitle?: string | null;
    text: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
    iconType?: string | null;
  }>;
};

export const PdpOverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pdpOverview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PdpOverviewComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuresSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupOfProductFeatureItems' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hpRating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taRating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBreadcrumbs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breadcrumbs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'internalLinkFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breadcrumbsExtended' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breadcrumbs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'internalLinkFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mediaItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MediaGalleryItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'location' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'disrupterObject' },
                },
              ],
            },
          },
        ],
      },
    },
    ...GroupOfProductFeatureItemsFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...MediaGalleryItemFragmentDoc.definitions,
    ...LocationFragmentDoc.definitions,
    ...DisrupterObjectFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetPdpPageContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPdpPageContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pdp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'pdpOverview' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'leadText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpOrlUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'ORL' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpDescriptionUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'PDP' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpRatingUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'PDP_RATING' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpMapUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'PDP_MAP' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpResortUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'PDP_RESORT' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpRegionUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'PDP_REGION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpClimateUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'PDP_CLIMATE' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pdpItineraryUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPage' },
                      value: { kind: 'EnumValue', value: 'PDP_ITINERARY' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PdpOverviewFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPdpPageContentQuery__
 *
 * To run a query within a React component, call `useGetPdpPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdpPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdpPageContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetPdpPageContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPdpPageContentQuery,
    GetPdpPageContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPdpPageContentQuery,
    GetPdpPageContentQueryVariables
  >(GetPdpPageContentDocument, options);
}
export function useGetPdpPageContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPdpPageContentQuery,
    GetPdpPageContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPdpPageContentQuery,
    GetPdpPageContentQueryVariables
  >(GetPdpPageContentDocument, options);
}
export type GetPdpPageContentQueryHookResult = ReturnType<
  typeof useGetPdpPageContentQuery
>;
export type GetPdpPageContentLazyQueryHookResult = ReturnType<
  typeof useGetPdpPageContentLazyQuery
>;
export type GetPdpPageContentQueryResult = Apollo.QueryResult<
  GetPdpPageContentQuery,
  GetPdpPageContentQueryVariables
>;
