import { SingleOrlItem } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { mapOrlFilterValuesToPdpFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import usePDPSearchValues from 'components/domain/pdp/usePDPSearchValues';
import { useGetOrlPriceDateOverviewQuery } from 'graphql/orl/GetORLPriceDateOverview.generated';
import { mapSearchControlStateToPdpSearchCriteriaInput } from './Orl.mappers';

interface IGetPriceOverview {
  data: SingleOrlItem[];
}

export default function useGetPriceOverview(): IGetPriceOverview {
  const { id } = useObjectId();

  const { searchControl, filters, loading } = usePDPSearchValues();

  const { data: offersData } = useGetOrlPriceDateOverviewQuery({
    ssr: false,
    skip: loading,
    variables: {
      id,
      searchControl:
        mapSearchControlStateToPdpSearchCriteriaInput(searchControl),
      filters: mapOrlFilterValuesToPdpFilterCriteriaInput(filters),
    },
  });

  return {
    data: offersData?.orl.priceDateOverview?.orlItems as SingleOrlItem[],
  };
}
