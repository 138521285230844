import { Trans } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

interface ISunHoursPerYearProps {
  hours: string;
}

const Label = styled.p(
  sx2CssThemeFn({
    pt: 2,
    pb: 3,
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '.icon': {
      mx: 2,
    },
    span: {
      mr: 1,
    },
  })
);

const SunHoursPerYear: React.FC<ISunHoursPerYearProps> = ({
  hours,
}: ISunHoursPerYearProps) => {
  return (
    <Label>
      <Trans
        i18nKey={'common:climate.sunHoursPerYear'}
        components={[
          <Icon
            key="icon"
            name="sun"
            className="climate-info-content-icon"
            size="xxlg"
          />,
          <span key="hours" />,
        ]}
        tOptions={{ hours }}
      />
    </Label>
  );
};

export default SunHoursPerYear;
