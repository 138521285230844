import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type ClimateComponentFragment = {
  __typename?: 'ClimateComponent';
  title: string;
  sunHoursPerYear: string;
  charts: Array<{
    __typename?: 'ClimateChart';
    title: string;
    type: Types.ClimateChartType;
    entries: Array<{
      __typename?: 'ClimateChartEntry';
      month: string;
      value: number;
    }>;
  }>;
};

export type ClimateChartFragment = {
  __typename?: 'ClimateChart';
  title: string;
  type: Types.ClimateChartType;
  entries: Array<{
    __typename?: 'ClimateChartEntry';
    month: string;
    value: number;
  }>;
};

export type ClimateChartEntryFragment = {
  __typename?: 'ClimateChartEntry';
  month: string;
  value: number;
};

export const ClimateChartEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'climateChartEntry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClimateChartEntry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ClimateChartFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'climateChart' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClimateChart' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'climateChartEntry' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ClimateChartEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const ClimateComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'climateComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClimateComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'climateChart' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sunHoursPerYear' } },
        ],
      },
    },
    ...ClimateChartFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
