import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
export type BreadcrumbsFragment = {
  __typename?: 'BreadcrumbsComponent';
  breadcrumbs: Array<{
    __typename?: 'Breadcrumb';
    caption: string;
    link?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
  }>;
};

export type BreadcrumbFragment = {
  __typename?: 'Breadcrumb';
  caption: string;
  link?: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  } | null;
};

export const BreadcrumbFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'breadcrumb' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Breadcrumb' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const BreadcrumbsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'breadcrumbs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BreadcrumbsComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breadcrumbs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'breadcrumb' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BreadcrumbFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
