import { ListItem } from 'schema-dts';
import { Breadcrumb } from '@hotelplan/graphql.types';
import { languageDefault } from '@hotelplan/libs.language';
import { MediaComponentFragment } from 'graphql/media/MediaComponent.generated';

export function getAssetUrl(mediaItem: MediaComponentFragment) {
  let assetUrl = '';

  switch (mediaItem.__typename) {
    case 'ImageMediaItem':
      assetUrl = mediaItem.image.resized[0].url;
      break;
    case 'ExternalMediaItem':
    case 'MusicMediaItem':
    case 'PdfMediaItem':
    case 'VideoMediaItem':
      assetUrl = mediaItem.resource;
      break;
    default:
      break;
  }

  return assetUrl;
}

export function getGeoStructuredData(
  breadCrumbItems: Breadcrumb[] | undefined,
  language?: string
) {
  const rootJsonBreadCrumb: ListItem = {
    '@type': 'ListItem',
    position: 1,
    item: {
      '@type': 'WebPage',
      '@id': `${window.location.origin}${
        language === languageDefault ? '' : `/${language}/`
      }`,
      name: 'Home',
    },
  };

  const jsonLdBreadcrumbs: ListItem[] =
    breadCrumbItems?.map(({ caption, link: { uri } }, i) => ({
      '@type': 'ListItem',
      position: i + 2,
      item: {
        '@type': 'WebPage',
        '@id': `${window.location.origin}${uri}`,
        name: caption,
      },
    })) || [];

  return {
    '@context': 'https://schema.org' as const,
    '@type': 'BreadcrumbList' as const,
    itemListElement: [rootJsonBreadCrumb, ...jsonLdBreadcrumbs],
  };
}
